import ApiService from "../apiservice";



class NotasService extends ApiService{

    constructor(){
        super('/gerarLinkApostila')
    }

    gerarTokenPortalApostila(codigoCurso){

        return this.get("?codigoCurso=" + codigoCurso);
    }


}

export default NotasService