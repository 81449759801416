import React from "react";
import FramePlayer from "../components/framePlayer";
import LocalStorageService from "../app/service/localstorageService";
import NotasService from "../app/service/notas";
import { withRouter } from "react-router-dom";
import HomeService from "../app/service/homeService";
import * as message from "../components/toastr";
import { AuthContext } from "../main/provedorAutenticacao";
import MatriculaService from "../app/service/matriculaService";
import { Modal, Row } from "react-bootstrap";
import DadosAulaService from "../app/service/dadosAulaService";
import AvaliacaoAulaCurso from "../components/notaAulaCurso";
import CursoService from "../app/service/cursoService";
import CustomSpeedDial from "../components/CustomSpeedDial";
import ApostilaService from "../app/service/apostilaService";
import { error } from "jquery";

class Player extends React.Component {
  constructor() {
    super();
    this.notaService = new NotasService();
    this.service = new HomeService();
    this.dadosService = new DadosAulaService();
    this.serviceMat = new MatriculaService();
    this.cursoService = new CursoService();
    this.apostilaService = new ApostilaService();
    // this.handleMessage = this.handleMessage.bind(this);
    this.ultimaTelaGravada = 0;
    this.buscouDadosAula = false; //variável par identificar se os dados já foi solicitado
    this.gravouNota = false;
  }

  state = {
    curso: {},
    urlAula: "",
    historia: false,
    modalConclusao: false,
    avaliarAula: false,
    tela_onde_parou: 0,
  };

  voltarHome = () => {
    this.props.history.push("/home");
  };

  verificaAShistoria = () => {
    this.setState({ historia: true }, () => this.gerarurl());
  };

  verificaAStutorial = () => {
    //inserir metodo para registrar no banco de dados

    const curso = this.state.curso;
    curso.tutorial = "S";
    curso.historia = "S";
    this.serviceMat
      .setarTutorialEHistoria()
      .then((response) => {
        this.setState({ curso: curso }, () => this.gerarurl());
      })
      .catch((erro) => {
        console.error(erro.response.data);
      });
  };

  mapaCurso = () => {
    const indexAula = this.state.urlAula.search("aula");

    if (indexAula === -1) {
      this.gerarUrlMapaCurso();
    } else {
      if (
        this.state.curso.codigo === 240 ||
        this.state.curso.codigo === 238 ||
        this.state.curso.codigo === 185 ||
        this.state.curso.codigo === 203 ||
        this.state.curso.codigo === 242 ||
        this.state.curso.codigo === 239 ||
        this.state.curso.codigo === 237 ||
        this.state.curso.codigo === 184 ||
        this.state.curso.codigo === 204 ||
        this.state.curso.codigo === 243
      ) {
        this.props.history.push("/home");
      } else {
        this.gerarUrlMapaCurso();
      }
    }
  };

  mapaPrincipal = () => {
    this.gerarUrlMapaPrincipal();
  };

  verificaAula = () => {
    this.dadosService
      .deletarDados(this.state.curso.id)
      .then((response) => {
        console.log("Checkpoint removido!");
      })
      .catch((erro) => {
        console.error(erro.response);
      });

    this.atualizarCurso();
  };

  chamaMapaCurso = () => {
    this.gerarUrlMapaCurso();
  };

  chamaAula = () => {
    this.gerarUrlAula();
  };

  chamaHistoria = () => {
    this.setState({
      urlAula: `${this.state.curso.caminho}/../historia/index.html`,
    });
  };

  chamaTutorial = () => {
    this.setState({
      urlAula: `${this.state.curso.caminho}/../tutorial/index.html`,
    });
  };

  buscarOndeParou = () => {
    this.buscarDadosAula();
    this.buscouDadosAula = true;
  };

  respostaframe = (data) => {
    const mensagemData = data.toString();
    const mensagemDataString = mensagemData.split("_");

    if (mensagemDataString[0] === "gravarOndeParou") {
      this.gravarDadosAula(mensagemDataString[1]);
      this.ultimaTelaGravada = mensagemDataString[1];
    }
  };

  gravarNota = (nota) => {
    if (this.state.curso.exerciciosDigitais === "S") {
      this.notaService
        .gravarNotaSemAvancarAula({
          curso: this.state.curso.id,
          aula: this.state.curso.aulaAtual,
          nota: nota,
        })
        .then((response) => {
          if (response.data === "Concluído") {
            this.setState({ modalConclusao: true });
          }
          console.log("gravou nota ", nota);
        })
        .catch((error) => {
          console.error(error.response.data);
        });
    } else {
      this.notaService
        .gravarNota({
          curso: this.state.curso.id,
          aula: this.state.curso.aulaAtual,
          nota: nota,
        })
        .then((response) => {
          if (response.data === "Concluído") {
            this.setState({ modalConclusao: true });
          }
          console.log("gravou nota ", nota);
          this.setState({ avaliarAula: true });
          this.verificaBloqueioCursoApostila();
        })
        .catch((error) => {
          console.error(error.response.data);
        });
    }
  };

  gerarUrlAula = async () => {
    await this.setState({
      urlAula: `${this.state.curso.caminho}/aula${this.state.curso.aulaAtual}/index.html`,
    });
    console.log(this.state.urlAula);
  };

  reverVideo = async () => {
    this.dadosService.deletarDados(this.state.curso.id).then((response) => {
      window.location.reload();
    });
  };

  gerarUrlMapaPrincipal = () => {
    this.setState({ urlAula: `${this.state.curso.caminho}/mapa/index.html` });
  };

  gerarUrlMapaCurso = () => {
    this.service
      .buscarCursoAtual()
      .then((response) => {
        this.setState({
          curso: response.data,
          urlAula: `${response.data.caminho}/mapas/mapa${response.data.aulaAtual}/index.html`,
        });

        LocalStorageService.adicionarItem("_curso_atual", response.data);
      })
      .catch((erro) => {
        console.error(erro.response.data);
      });

    // this.setState({ urlAula: `http://www.acessocurso.com.br/ead/${this.state.curso.caminho}/mapas/mapa${this.state.curso.aulaAtual}/index.html` })
  };

  gerarurl = () => {
    if (this.state.curso === "Nenhum Curso em Andamento ou em Espera.") {
      message.mensagemAlert("Nenhum Curso em Andamento ou em Espera.");
      this.context.encerrarSessao();
    }

    if (this.state.curso.mapakids === "S") {
      if (
        this.state.curso.tutorial === "N" &&
        this.state.curso.curso_tem_tutorial === "S"
      ) {
        if (this.state.historia === false) {
          this.setState({
            urlAula: "cursos/kids/arquipelagoSaber/historia/index.html",
          });
        } else {
          this.setState({
            urlAula: "cursos/kids/arquipelagoSaber/tutorial/index.html",
          });
        }
      } else {
        this.gerarUrlMapaPrincipal();
      }
    } else {
      this.setState({
        urlAula: `${this.state.curso.caminho}/aula${this.state.curso.aulaAtual}/index.html`,
      });
    }
  };

  atualizarCurso = async () => {
    this.gravouNota = false;

    await this.service
      .buscarCursoAtual()
      .then(async (response) => {
        await this.setState(
          {
            curso: response.data,
          },
          () => this.gerarUrlAula()
        );

        LocalStorageService.adicionarItem("_curso_atual", response.data);

        if (response.data.bloqueado === true) {
          this.props.history.push("/home");
        } else {
          window.location.reload();
        }
      })
      .catch((erro) => {
        //precisa tratar quando o aluno não tiver mais cursos
        console.error(erro.response);
      });
  };

  voltarParaHome = () => {
    this.props.history.push("/home");
  };

  abrirArquivos = () => {
    if (
      this.state.curso.arquivos === null ||
      this.state.curso.arquivos === ""
    ) {
      message.mensagemAlert("Nenhum link foi encontrado!");
    } else {
      const linkArquivo = this.state.curso.arquivos;
      window.open(linkArquivo, "_blank");
    }
  };

  abrirApostila = () => {
    this.apostilaService
      .gerarTokenPortalApostila(this.state.curso.codigo)
      .then((response) => {
        window.open(
          `https://apostiladigital.grupooportunidade.com.br/loginToken/${response.data}`,
          "_blank"
        );
      })
      .catch(() => {
        message.mensagemAlert(
          "Houve um problema ao gerar o acesso à apostila. Entre em contato com o suporte."
        );
      });
  };

  abrirDicionarioLibras = () => {
    if (this.state.curso.codigo === 230) {
      window.open(
        `http://ead.grupooportunidade.com.br/ead/dicionarioLibras/librasBasico.html`,
        "_blank"
      );
      // window.open(`http://localhost:3001/dicionarioLibras/librasBasico.html`, '_blank');
    } else if (this.state.curso.codigo === 235) {
      window.open(
        `http://ead.grupooportunidade.com.br/ead/dicionarioLibras/librasIntermediario.html`,
        "_blank"
      );
      // window.open(`http://localhost:3001/dicionarioLibras/librasIntermediario.html`, '_blank');
    } else if (this.state.curso.codigo === 236) {
      window.open(
        `http://ead.grupooportunidade.com.br/ead/dicionarioLibras/librasAvancado.html`,
        "_blank"
      );
      // window.open(`http://localhost:3001/dicionarioLibras/librasAvancado.html`, '_blank');
    }
  };

  async componentDidMount() {
    const curso = LocalStorageService.obterItem("_curso_atual");
    await this.setState({ curso: curso }, () => {
      this.verificaBloqueioCursoApostila();
      this.buscarDadosAula();
    });
    this.gerarurl();
  }

  gravarDadosAula = (nTela) => {
    if (parseInt(this.ultimaTelaGravada) < parseInt(nTela)) {
      const dto = {
        idcurso: this.state.curso.id,
        aula: this.state.curso.aulaAtual,
        slide: nTela,
      };

      this.dadosService
        .gravarDados(dto)
        .then((response) => {})
        .catch((erro) => {
          console.error("Erro ao gravar dados da aula");
          console.error(erro.response);
        });
    }
  };

  verificaBloqueioCursoApostila = () => {
    this.cursoService
      .bloqueioDeApostila(this.state.curso.id)
      .then((response) => {
        if (response.data === true) {
          message.mensagemAlert(
            "Você precisa entrar em contato com a secretaria e solicitar a apostila."
          );
          this.props.history.push("/home");
        }
      })
      .catch((erro) => {
        console.error("Erro ao verificar bloqueioCursoApostila!");
      });
  };

  buscarDadosAula = () => {
    if (this.buscouDadosAula === false) {
      const dto = {
        idcurso: this.state.curso.id,
        aula: this.state.curso.aulaAtual,
        tela: 0,
      };

      this.dadosService
        .buscarDados(dto.idcurso)
        .then((response) => {
          let tela = response.data.slide;
          if (dto.aula === response.data.aula) {
            this.setState({ tela_onde_parou: tela });

            console.log("buscou dados aula ", tela);
          }
        })
        .catch((erro) => {
          console.error(erro.response.data);
        });
    }
  };

  fechandoModalCunclusao = () => {
    this.props.history.push("/home");
  };

  render() {
    return (
      <div>
        <FramePlayer
          urlAula={this.state.urlAula}
          teste={() => {
            this.teste();
          }}
          mapaCurso={() => {
            this.mapaCurso();
          }}
          mapaPrincipal={() => {
            this.mapaPrincipal();
          }}
          verificaAula={() => {
            this.verificaAula();
          }}
          chamaAula={() => {
            this.chamaAula();
          }}
          chamaHistoria={() => {
            this.chamaHistoria();
          }}
          chamaTutorial={() => {
            this.chamaTutorial();
          }}
          verificaAShistoria={() => {
            this.verificaAShistoria();
          }}
          verificaAStutorial={() => {
            this.verificaAStutorial();
          }}
          buscarOndeParou={() => {
            this.buscarOndeParou();
          }}
          gravarNota={(nota) => {
            this.gravarNota(nota);
          }}
          respostaframe={(data) => {
            this.respostaframe(data);
          }}
          reverVideo={() => {
            this.reverVideo();
          }}
          gravouNota={this.gravouNota}
          history={this.props.history}
          possui_video={this.state.curso.aula_possui_video}
          tela_onde_parou={this.state.tela_onde_parou}
          curso={this.state.curso}
        />
        <div>
          <CustomSpeedDial
            abrirApostila={() => this.abrirApostila()}
            abrirArquivos={() => this.abrirArquivos()}
            abrirDicionarioLibras={() => this.abrirDicionarioLibras()}
            voltarHome={() => this.voltarHome()}
            curso={this.state.curso}
          />
        </div>

        <Modal
          show={this.state.modalConclusao}
          onHide={this.fechandoModalCunclusao}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Curso Concluído!
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <img src="conclusao.jpg" alt="conclusão" loading="lazy" />
            </Row>
          </Modal.Body>
        </Modal>

        <AvaliacaoAulaCurso
          curso={this.state.curso}
          avaliar={this.state.avaliarAula}
        />
      </div>
    );
  }
}

Player.contextType = AuthContext;

export default withRouter(Player);
