import { useEffect, useRef, useState } from "react";
import Iframe from "react-iframe";
import ReactPlayer from "react-player";
import "./VideoPlayer.css";

const FramePlayer = (props) => {

  const [exibirPlayerVideo, setExibirPlayerVideo] = useState(false);
  const [seeking, setSeeking] = useState(false); // Flag para evitar múltiplos seeks
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true); // Mantém o estado playing como true para reprodução contínua

  const handleVisibilityChange = () => {
    if (document.hidden) {
      setPlaying(false); // Pausa o vídeo
    } else if (!seeking) {
      setPlaying(true); // Retoma o vídeo quando a aba estiver visível novamente
    }
  };


  const handleSeekEnd = () => {
    setSeeking(false);
    setPlaying(true); // Retoma a reprodução quando o seek termina
  };


  // Função para carregar o próximo vídeo automaticamente
  const chamarInteratividade = () => {
    setExibirPlayerVideo(false);
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [seeking]);

  useEffect(() => {
    // Verifica se props.tela_onde_parou e props.possui_video foram carregados com valores válidos
    if (props.tela_onde_parou !== undefined && props.possui_video !== undefined) {
        if (props.tela_onde_parou === 0 && props.possui_video === true) {
            setExibirPlayerVideo(true);
            setPlaying(true);
        } else {
            setExibirPlayerVideo(false);
        }
    }
}, [props.tela_onde_parou, props.possui_video]);



  useEffect(() => {
    const handler = (e) => {
      if (e.data === "loja") {
        props.history.push("/home");
      } else if (e.data === "verificaAShistoria") {
        props.verificaAShistoria();
      } else if (e.data === "verificaAStutorial") {
        props.verificaAStutorial();
      } else if (e.data >= 0 && e.data <= 100 && props.gravouNota === false) {
        let nota =
          window.frames["frameAula"].cpAPIInterface.getVariableValue(
            "cpInfoPercentage"
          );
        props.gravarNota(nota);
      } else if (e.data === "mapaCurso") {
        props.mapaCurso();
      } else if (e.data === "mapaPrincipal") {
        props.mapaPrincipal();
      } else if (e.data === "verificaAula") {
        props.verificaAula();
      } else if (e.data === "chamaMapaCurso") {
        props.chamaMapaCurso();
      } else if (e.data === "chamaAula") {
        props.chamaAula();
      } else if (e.data === "chamaHome") {
        props.history.push("/home");
      } else if (e.data === "chamaHistoria") {
        props.chamaHistoria();
      } else if (e.data === "chamaTutorial") {
        props.chamaTutorial();
      } else if (e.data === "reverVideo") {
        props.reverVideo();
      } else if (e.data === "sairAS") {
        props.history.push("/home");
      } else if (e.data === "buscarOndeParou") {
       
        if (props.tela_onde_parou !== 0) {
          window.frames["frameAula"].cpAPIInterface.setVariableValue(
            "slide_ondeparou",
            props.tela_onde_parou - 1
          ); //considera o primeiro slide como 0
        }
      } else {
        props.respostaframe(e.data);
      }
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [props]);

  return (
    <>
      {exibirPlayerVideo === false ? (
        <Iframe
          url={props.urlAula}
          width="100%"
          height="100%"
          id="frameAula"
          name="frameAula"
          allowFullScreen={true}
          display="initial"
          position="absolute"
        />
      ) : (
        <div className="video-container">
          <ReactPlayer
            ref={playerRef}
            url={`${props.curso.caminho}/videos/aula${props.curso.aulaAtual}/master.m3u8`}
            playing={playing}
            controls={false}
            width="100%"
            height="100%"           
            onSeekEnd={handleSeekEnd} // Garante que o seeking é tratado corretamente
            onEnded={chamarInteratividade} // Chama o próximo vídeo automaticamente
          />
        </div>
      )}
    </>
  );
};

export default FramePlayer;
